import React from "react";
import logo from "../assets/equityworkx_logo.png";
import { Footer } from "../components/footer/Footer";

export function PrivacyPolicy() {
	return (
		<>
			<div className="App" id="privacyPolicy">
				<a href="/" className="App-link"><img src={logo} className="privacy-logo" alt="logo"/></a>
				<h1>Privacy Policy</h1>
				<h4>Last Updated March 18, 2024</h4>
				<section className="section">
					<p>
						This privacy notice for EquityWorkx ("we," "us," or "our"), describes how and why we might collect,
						store, use, and/or share ("process") your information when you use our services ("Services"), such
						as when you:
						<ul>
							<li>Visit our website at https://www.equityworkx.com, or any website of ours that links to this
								privacy notice
							</li>
							<li>Download and use our mobile application (EquityWorkx), or any other application of ours that
								links to this privacy notice
							</li>
							<li>Engage with us in other related ways, including any sales, marketing, or events</li>
						</ul>
						Questions or concerns? Reading this privacy notice will help you understand your privacy rights and
						choices. If you do not agree with our policies and practices, please do not use our Services. If you
						still have any questions or concerns, please contact us at info@equityworkx.com.
					</p>
				</section>
				<section className="section">
					<h5>SUMMARY OF KEY POINTS</h5>
					<p>This summary provides key points from our privacy notice, but you can find out more details about any
						of these topics by clicking the link following each key point or by using our table of contents
						below to find the section you are looking for.</p>
					<p>What personal information do we process? When you visit, use, or navigate our Services, we may
						process personal information depending on how you interact with us and the Services, the choices you
						make, and the products and features you use. Learn more about personal information you disclose to
						us.</p>
					<p>Do we process any sensitive personal information? We may process sensitive personal information when
						necessary with your consent or as otherwise permitted by applicable law. Learn more about sensitive
						information we process.</p>
					<p>Do we receive any information from third parties? We may receive information from public databases,
						marketing partners, social media platforms, and other outside sources. Learn more about information
						collected from other sources</p>
					<p>How do we process your information? We process your information to provide, improve, and administer
						our Services, communicate with you, for security and fraud prevention, and to comply with law. We
						may also process your information for other purposes with your consent. We process your information
						only when we have a valid legal reason to do so. Learn more about how we process your
						information.</p>
					<p>In what situations and with which types of parties do we share personal information? We may share
						information in specific situations and with specific categories of third parties. Learn more about
						when and with whom we share your personal information.</p>
					<p>How do we keep your information safe? We have organizational and technical processes and procedures
						in place to protect your personal information. However, no electronic transmission over the internet
						or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
						guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
						defeat our security and improperly collect, access, steal, or modify your information. Learn more
						about how we keep your information safe.</p>
					<p>What are your rights? Depending on where you are located geographically, the applicable privacy law
						may mean you have certain rights regarding your personal information. Learn more about your privacy
						rights.</p>
					<p>How do you exercise your rights? The easiest way to exercise your rights is by visiting
						https://www.equityworkx.com/privacypolicy#deleteYourAccount, or by contacting us. We will consider
						and act upon any request in accordance with applicable data protection laws.</p>
					<p>Want to learn more about what we do with any information we collect? Review the privacy notice in
						full.</p>
				</section>
				<section className="section" id="toc">
					<h5>TABLE OF CONTENTS</h5>
					<p>
						<ol>
							<li><a href="#whatIsCollected">WHAT INFORMATION DO WE COLLECT?</a></li>
							<li><a href="#howDoWeProcess">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
							<li><a href="#whenDoWeShare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
							<li><a href="#socialLogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
							<li><a href="#keepAlive">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
							<li><a href="#security">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
							<li><a href="#minors">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
							<li><a href="#privacyRights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
							<li><a href="#noNotTrackControls">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
							<li><a href="#specificRights">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
							<li><a href="#updatesToThisNotice">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
							<li><a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
							<li><a href="#personalDataUpdates">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
						</ol>
					</p>
				</section>
				<section className="section" id="whatIsCollected">
					<h4>WHAT INFORMATION DO WE COLLECT?</h4>
					<h5>Personal information you disclose to us</h5>
					<p>In Short: We collect personal information that you provide to us.</p>
					<p>We collect personal information that you voluntarily provide to us when you register on the Services,
						express an interest in obtaining information about us or our products and Services, when you
						participate in activities on the Services, or otherwise when you contact us.</p>
					<p>Personal Information Provided by You. The personal information that we collect depends on the context
						of your interactions with us and the Services, the choices you make, and the products and features
						you use. The personal information we collect may include the following:
						<ul>
							<li>names</li>
							<li>email addresses</li>
							<li>phone numbers</li>
							<li>usernames</li>
							<li>passwords</li>
							<li>contact or authentication data</li>
							<li>debit/credit card numbers</li>
							<li>mortgage loan numbers</li>
							<li>banking account numbers</li>
						</ul>
					</p>
					<p>Sensitive Information. When necessary, with your consent or as otherwise permitted by applicable law,
						we process the following categories of sensitive information:
						<ul>
							<li>financial data</li>
						</ul>
					</p>
					<p>Social Media Login Data. We may provide you with the option to register with us using your existing
						social media account details, like your Facebook, Twitter, or other social media account. If you
						choose to register in this way, we will collect the information described in the section called "HOW
						DO WE HANDLE YOUR SOCIAL LOGINS?" below.</p>
					<p>Application Data. If you use our application(s), we also may collect the following information if you
						choose to provide us with access or permission:
						<ul>
							<li>Push Notifications. We may request to send you push notifications regarding your account or
								certain features of the application(s). If you wish to opt out from receiving these types of
								communications, you may turn them off in your device's settings.
							</li>
						</ul>
						This information is primarily needed to maintain the security and operation of our application(s),
						for troubleshooting, and for our internal analytics and reporting purposes.
					</p>
					<p>All personal information that you provide to us must be true, complete, and accurate, and you must
						notify us of any changes to such personal information.</p>
					<h5>Information automatically collected</h5>
					<p>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device
						characteristics — is collected automatically when you visit our Services.</p>
					<p>We automatically collect certain information when you visit, use, or navigate the Services. This
						information does not reveal your specific identity (like your name or contact information) but may
						include device and usage information, such as your IP address, browser and device characteristics,
						operating system, language preferences, referring URLs, device name, country, location, information
						about how and when you use our Services, and other technical information. This information is
						primarily needed to maintain the security and operation of our Services, and for our internal
						analytics and reporting purposes.</p>
					<p>The information we collect includes:
						<ul>
							<li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and
								performance information our servers automatically collect when you access or use our
								Services and which we record in log files. Depending on how you interact with us, this log
								data may include your IP address, device information, browser type, and settings and
								information about your activity in the Services (such as the date/time stamps associated
								with your usage, pages and files viewed, searches, and other actions you take such as which
								features you use), device event information (such as system activity, error reports
								(sometimes called "crash dumps"), and hardware settings).
							</li>
							<li>Device Data. We collect device data such as information about your computer, phone, tablet,
								or other device you use to access the Services. Depending on the device used, this device
								data may include information such as your IP address (or proxy server), device and
								application identification numbers, location, browser type, hardware model, Internet service
								provider and/or mobile carrier, operating system, and system configuration information.
							</li>
						</ul>
					</p>
					<h5>Information collected from other sources</h5>
					<p>In Short: We may collect limited data from public databases, marketing partners, social media
						platforms, and other outside sources.</p>
					<p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update
						our records, we may obtain information about you from other sources, such as public databases, joint
						marketing partners, affiliate programs, data providers, social media platforms, and from other third
						parties. This information includes mailing addresses, job titles, email addresses, phone numbers,
						intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social
						media URLs, and custom profiles, for purposes of targeted advertising and event promotion. If you
						interact with us on a social media platform using your social media account (e.g., Facebook or
						Twitter), we receive personal information about you such as your name, email address, and gender.
						Any personal information that we collect from your social media account depends on your social media
						account's privacy settings.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="howDoWeProcess">
					<h4>HOW DO WE PROCESS YOUR INFORMATION?</h4>
					<p>
						In Short: We process your information to provide, improve, and administer our Services, communicate
						with you, for security and fraud prevention, and to comply with law. We may also process your
						information for other purposes with your consent.</p>
					<p>We process your personal information for a variety of reasons, depending on how you interact with our
						Services, including:
						<ul>
							<li>To facilitate account creation and authentication and otherwise manage user accounts. We may
								process your information so you can create and log in to your account, as well as keep your
								account in working order.
							</li>
							<li>To deliver and facilitate delivery of services to the user. We may process your information
								to provide you with the requested service.
							</li>
							<li>To respond to user inquiries/offer support to users. We may process your information to
								respond to your inquiries and solve any potential issues you might have with the requested
								service.
							</li>
							<li>To send administrative information to you. We may process your information to send you
								details about our products and services, changes to our terms and policies, and other
								similar information.
							</li>
							<li>To fulfill and manage your orders. We may process your information to fulfill and manage
								your orders, payments, returns, and exchanges made through the Services.
							</li>
							<li>To send you marketing and promotional communications. We may process the personal
								information you send to us for our marketing purposes, if this is in accordance with your
								marketing preferences. You can opt out of our marketing emails at any time. For more
								information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.
							</li>
							<li>To deliver targeted advertising to you. We may process your information to develop and
								display personalized content and advertising tailored to your interests, location, and more.
							</li>
							<li>To protect our Services. We may process your information as part of our efforts to keep our
								Services safe and secure, including fraud monitoring and prevention.
							</li>
							<li>To evaluate and improve our Services, products, marketing, and your experience. We may
								process your information when we believe it is necessary to identify usage trends, determine
								the effectiveness of our promotional campaigns, and to evaluate and improve our Services,
								products, marketing, and your experience.
							</li>
							<li>To identify usage trends. We may process information about how you use our Services to
								better understand how they are being used so we can improve them.
							</li>
						</ul>
					</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="whenDoWeShare">
					<h4>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
					<p>
						In Short: We may share information in specific situations described in this section and/or with the
						following categories of third parties.</p>
					<p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with
						third-party vendors, service providers, contractors, or agents ("third parties") who perform
						services for us or on our behalf and require access to such information to do that work. The
						categories of third parties we may share personal information with are as follows:
						<ul>
							<li>Affiliate Marketing Programs</li>
							<li>Sales & Marketing Tools</li>
							<li>User Account Registration & Authentication Services</li>
						</ul>
					</p>
					<p>We also may need to share your personal information in the following situations:
						<ul>
							<li>Business Transfers. We may share or transfer your information in connection with, or during
								negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
								portion of our business to another company.
							</li>
							<li>When we use Google Analytics. We may share your information with Google Analytics to track
								and analyze the use of the Services. The Google Analytics Advertising Features that we may
								use include: Google Display Network Impressions Reporting and Google Analytics Demographics
								and Interests Reporting. To opt out of being tracked by Google Analytics across the
								Services, visit https://tools.google.com/dlpage/gaoptout. You can opt out of Google
								Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other
								opt out means include http://optout.networkadvertising.org/ and
								http://www.networkadvertising.org/mobile-choice. For more information on the privacy
								practices of Google, please visit the Google Privacy & Terms page.
							</li>
							<li>Affiliates. We may share your information with our affiliates, in which case we will require
								those affiliates to honor this privacy notice. Affiliates include our parent company and any
								subsidiaries, joint venture partners, or other companies that we control or that are under
								common control with us.
							</li>
							<li>Business Partners. We may share your information with our business partners to offer you
								certain products, services, or promotions.
							</li>
						</ul>
					</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="socialLogins">
					<h4>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>
					<p>In Short: If you choose to register or log in to our Services using a social media account, we may
						have access to certain information about you.</p>
					<p>Our Services offer you the ability to register and log in using your third-party social media account
						details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain
						profile information about you from your social media provider. The profile information we receive
						may vary depending on the social media provider concerned, but will often include your name, email
						address, friends list, and profile picture, as well as other information you choose to make public
						on such a social media platform.</p>
					<p>We will use the information we receive only for the purposes that are described in this privacy
						notice or that are otherwise made clear to you on the relevant Services. Please note that we do not
						control, and are not responsible for, other uses of your personal information by your third-party
						social media provider. We recommend that you review their privacy notice to understand how they
						collect, use, and share your personal information, and how you can set your privacy preferences on
						their sites and apps.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="keepAlive">
					<h4>HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
					<p>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this
						privacy notice unless otherwise required by law.</p>
					<p>We will only keep your personal information for as long as it is necessary for the purposes set out
						in this privacy notice, unless a longer retention period is required or permitted by law (such as
						tax, accounting, or other legal requirements). No purpose in this notice will require us keeping
						your personal information for longer than the period of time in which users have an account with
						us. </p>
					<p>When we have no ongoing legitimate business need to process your personal information, we will either
						delete or anonymize such information, or, if this is not possible (for example, because your
						personal information has been stored in backup archives), then we will securely store your personal
						information and isolate it from any further processing until deletion is possible.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="security">
					<h4>HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
					<p>In Short: We aim to protect your personal information through a system of organizational and
						technical security measures.</p>
					<p>We have implemented appropriate and reasonable technical and organizational security measures
						designed to protect the security of any personal information we process. However, despite our
						safeguards and efforts to secure your information, no electronic transmission over the Internet or
						information storage technology can be guaranteed to be 100% secure, so we cannot promise or
						guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
						defeat our security and improperly collect, access, steal, or modify your information. Although we
						will do our best to protect your personal information, transmission of personal information to and
						from our Services is at your own risk. You should only access the Services within a secure
						environment.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="minors">
					<h4>DO WE COLLECT INFORMATION FROM MINORS?</h4>
					<p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>
					<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the
						Services, you represent that you are at least 18 or that you are the parent or guardian of such a
						minor and consent to such minor dependent’s use of the Services. If we learn that personal
						information from users less than 18 years of age has been collected, we will deactivate the account
						and take reasonable measures to promptly delete such data from our records. If you become aware of
						any data we may have collected from children under age 18, please contact us at
						info@equityworkx.com.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="privacyRights">
					<h4>WHAT ARE YOUR PRIVACY RIGHTS?</h4>
					<p>nIn Short: You may review, change, or terminate your account at any time.</p>
					<p>Withdrawing your consent: If we are relying on your consent to process your personal information,
						which may be express and/or implied consent depending on the applicable law, you have the right to
						withdraw your consent at any time. You can withdraw your consent at any time by contacting us by
						using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
						below.</p>
					<p>However, please note that this will not affect the lawfulness of the processing before its withdrawal
						nor, when applicable law allows, will it affect the processing of your personal information
						conducted in reliance on lawful processing grounds other than consent.</p>
					<p>Opting out of marketing and promotional communications: You can unsubscribe from our marketing and
						promotional communications at any time by clicking on the unsubscribe link in the emails that we
						send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT
						THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still
						communicate with you — for example, to send you service-related messages that are necessary for the
						administration and use of your account, to respond to service requests, or for other non-marketing
						purposes.</p>
					<h5>Account Information</h5>
					<p>If you would at any time like to review or change the information in your account or terminate your
						account, you can:
						<ul>
							<li>Log in to your account settings and update your user account.</li>
						</ul>
						Upon your request to terminate your account, we will deactivate or delete your account and
						information from our active databases. However, we may retain some information in our files to
						prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or
						comply with applicable legal requirements.
					</p>
					<p>If you have questions or comments about your privacy rights, you may email us at
						info@equityworkx.com.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="noNotTrackControls">
					<h4>CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
					<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
						("DNT") feature or setting you can activate to signal your privacy preference not to have data about
						your online browsing activities monitored and collected. At this stage no uniform technology
						standard for recognizing and implementing DNT signals has been finalized. As such, we do not
						currently respond to DNT browser signals or any other mechanism that automatically communicates your
						choice not to be tracked online. If a standard for online tracking is adopted that we must follow in
						the future, we will inform you about that practice in a revised version of this privacy notice.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="specificRights">
					<h4>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
					<p>In short: If you are a resident of Utah, Colorado, California, Connecticut or Virginia, you are
						granted specific rights regarding access to your personal information.</p>
					<p>What categories of personal information do we collect?</p>
					<p>We have collected the following categories of personal information in the past twelve (12)
						months:</p>
					<table className="borderedTable">
						<thead>
						<tr>
							<th>Category</th>
							<th>Examples</th>
							<th>Collected</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td>A. Identifiers</td>
							<td>nContact details, such as real name, alias, postal address, telephone or mobile contact
								number, unique personal identifier, online identifier, Internet Protocol address, email
								address, and account name.
							</td>
							<td>Yes</td>
						</tr>
						<tr>
							<td>B. Personal information categories listed in the California Customer Records statute</td>
							<td>Name, contact information, education, employment, employment history, and financial
								information.
							</td>
							<td>Yes</td>
						</tr>
						<tr>
							<td>C. Protected classification characteristics under state or federal law.</td>
							<td>Gender and date of birth.</td>
							<td>Yes</td>
						</tr>
						<tr>
							<td>D. Commercial information</td>
							<td>Transaction information, purchase history, financial details, and payment information.</td>
							<td>Yes</td>
						</tr>
						<tr>
							<td>E. Biometric information</td>
							<td>Fingerprints and voiceprints.</td>
							<td>No</td>
						</tr>
						<tr>
							<td>F. Internet or other similar network activity</td>
							<td>nBrowsing history, search history, online behavior, interest data, and interactions with our
								and other websites, applications, systems, and advertisements.
							</td>
							<td>No</td>
						</tr>
						<tr>
							<td>G. Geolocation data</td>
							<td>Device location</td>
							<td>No</td>
						</tr>
						<tr>
							<td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
							<td>Images and audio, video or call recordings created in connection with our business
								activities
							</td>
							<td>No</td>
						</tr>
						<tr>
							<td>I. Professional or employment-related information</td>
							<td>Business contact details in order to provide you our Services at a business level or job
								title, work history, and professional qualifications if you apply for a job with us.
							</td>
							<td>No</td>
						</tr>
						<tr>
							<td>J. Education Information</td>
							<td>Student records and directory information.</td>
							<td>No</td>
						</tr>
						<tr>
							<td>K. Inferences drawn from collected personal information</td>
							<td>Inferences drawn from any of the collected personal information listed above to create a
								profile or summary about, for example, an individual’s preferences and characteristics.
							</td>
							<td>No</td>
						</tr>
						<tr>
							<td>Sensitive personal Information</td>
							<td>Account login information and debit or credit card numbers</td>
							<td>Yes</td>
						</tr>
						</tbody>
					</table>
					<p>We will use and retain the collected personal information as needed to provide the Services or for:
						<ul>
							<li>Category A - As long as the user has an account with us</li>
							<li>Category B - As long as the user has an account with us</li>
							<li>Category C - As long as the user has an account with us</li>
							<li>Category D - As long as the user has an account with us</li>
							<li>Category L - As long as the user has an account with us</li>
						</ul>
						Category L information may be used, or disclosed to a service provider or contractor, for
						additional, specified purposes. You have the right to limit the use or disclosure of your sensitive
						personal information.
					</p>
					<p>We may also collect other personal information outside of these categories through instances where
						you interact with us in person, online, or by phone or mail in the context of:
						<ul>
							<li>Receiving help through our customer support channels;</li>
							<li>Participation in customer surveys or contests; and</li>
							<li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
						</ul>
					</p>
					<h5>How do we use and share your personal information?</h5>
					<p>Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR
						INFORMATION?"</p>
					<h5>Will your information be shared with anyone else?</h5>
					<p>We may disclose your personal information with our service providers pursuant to a written contract
						between us and each service provider. Learn more about how we disclose personal information to in
						the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</p>
					<p>We may use your personal information for our own business purposes, such as for undertaking internal
						research for technological development and demonstration. This is not considered to be "selling" of
						your personal information.</p>
					<p>We have disclosed the following categories of personal information to third parties for a business or
						commercial purpose in the preceding twelve (12) months:</p>
					<p>The categories of third parties to whom we disclosed personal information for a business or
						commercial purpose can be found under "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
					</p>
					<p>We have sold or shared the following categories of personal information to third parties in the
						preceding twelve (12) months:</p>
					<p>The categories of third parties to whom we sold personal information are:
						<ul>
							<li>Affiliate Marketing Programs</li>
						</ul>
					</p>
					<p>The categories of third parties to whom we shared personal information with are:
						<ul>
							<li>Affiliate Marketing Programs</li>
						</ul>
					</p>
					<h4>California Residents</h4>
					<p>nCalifornia Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who
						are California residents to request and obtain from us, once a year and free of charge, information
						about categories of personal information (if any) we disclosed to third parties for direct marketing
						purposes and the names and addresses of all third parties with which we shared personal information
						in the immediately preceding calendar year. If you are a California resident and would like to make
						such a request, please submit your request in writing to us using the contact information provided
						below.</p>
					<p>If you are under 18 years of age, reside in California, and have a registered account with the
						Services, you have the right to request removal of unwanted data that you publicly post on the
						Services. To request removal of such data, please contact us using the contact information provided
						below and include the email address associated with your account and a statement that you reside in
						California. We will make sure the data is not publicly displayed on the Services, but please be
						aware that the data may not be completely or comprehensively removed from all our systems (e.g.,
						backups, etc.).</p>
					<h5>CCPA Privacy Notice</h5>
					<p>This section applies only to California residents. Under the California Consumer Privacy Act (CCPA),
						you have the rights listed below.</p>
					<p>The California Code of Regulations defines a "residents" as:
						<ol>
							<li>every individual who is in the State of California for other than a temporary or transitory
								purpose and
							</li>
							<li>every individual who is domiciled in the State of California who is outside the State of
								California for a temporary or transitory purpose
							</li>
						</ol>
					</p>
					<p>All other individuals are defined as "non-residents."</p>
					<p>If this definition of "resident" applies to you, we must adhere to certain rights and obligations
						regarding your personal information.</p>
					<h5>Your rights with respect to your personal data</h5>
					<p className="underline">Right to request deletion of the data — Request to delete</p>
					<p>You can ask for the deletion of your personal information. If you ask us to delete your personal
						information, we will respect your request and delete your personal information, subject to certain
						exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or
						her right to free speech, our compliance requirements resulting from a legal obligation, or any
						processing that may be required to protect against illegal activities.</p>
					<p className="underline">Right to be informed — Request to know</p>
					<p>Depending on the circumstances, you have a right to know:
						<ul>
							<li>whether we collect and use your personal information;</li>
							<li>the categories of personal information that we collect;</li>
							<li>the purposes for which the collected personal information is used;</li>
							<li>whether we sell or share personal information to third parties;</li>
							<li>the categories of personal information that we sold, shared, or disclosed for a business
								purpose;
							</li>
							<li>the categories of third parties to whom the personal information was sold, shared, or
								disclosed for a business purpose;
							</li>
							<li>the business or commercial purpose for collecting, selling, or sharing personal information;
								and
							</li>
							<li>the specific pieces of personal information we collected about you.</li>
						</ul>
						In accordance with applicable law, we are not obligated to provide or delete consumer information
						that is de-identified in response to a consumer request or to re-identify individual data to verify
						a consumer request.
					</p>
					<p className="underline">Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>
					<p>We will not discriminate against you if you exercise your privacy rights.</p>
					<p className="underline">Right to Limit Use and Disclosure of Sensitive Personal Information</p>
					<p>If the business collects any of the following:
						<ul>
							<li>social security information, drivers' licenses, state ID cards, passport numbers</li>
							<li>account login information</li>
							<li>credit card numbers, financial account information, or credentials allowing access to such
								accounts
							</li>
							<li>precise geolocation</li>
							<li>racial or ethnic origin, religious or philosophical beliefs, union membership</li>
							<li>the contents of email and text, unless the business is the intended recipient of the
								communication
							</li>
							<li>genetic data, biometric data, and health data</li>
							<li>data concerning sexual orientation and sex life</li>
						</ul>
						you have the right to direct that business to limit its use of your sensitive personal information
						to that use which is necessary to perform the Services.
					</p>
					<p>Once a business receives your request, they are no longer allowed to use or disclose your sensitive
						personal information for any other purpose unless you provide consent for the use or disclosure of
						sensitive personal information for additional purposes.</p>
					<p>Please note that sensitive personal information that is collected or processed without the purpose of
						inferring characteristics about a consumer is not covered by this right, as well as the publicly
						available information.</p>
					<p>To exercise your right to limit use and disclosure of sensitive personal information, please email
						info@equityworkx.com or visit: https://www.equityworkx.com/privacypolicy#deleteYourAccount.</p>
					<p className="underline">Verification process</p>
					<p>Upon receiving your request, we will need to verify your identity to determine you are the same
						person about whom we have the information in our system. These verification efforts require us to
						ask you to provide information so that we can match it with information you have previously provided
						us. For instance, depending on the type of request you submit, we may ask you to provide certain
						information so that we can match the information you provide with the information we already have on
						file, or we may contact you through a communication method (e.g., phone or email) that you have
						previously provided to us. We may also use other verification methods as the circumstances
						dictate.</p>
					<p>We will only use personal information provided in your request to verify your identity or authority
						to make the request. To the extent possible, we will avoid requesting additional information from
						you for the purposes of verification. However, if we cannot verify your identity from the
						information already maintained by us, we may request that you provide additional information for the
						purposes of verifying your identity and for security or fraud-prevention purposes. We will delete
						such additionally provided information as soon as we finish verifying you.</p>
					<p className="underline">Other privacy rights</p>
					<p>You may object to the processing of your personal information.
						<ul>
							<li>You may request correction of your personal data if it is incorrect or no longer relevant,
								or ask to restrict the processing of the information.
							</li>
							<li>You can designate an authorized agent to make a request under the CCPA on your behalf. We
								may deny a request from an authorized agent that does not submit proof that they have been
								validly authorized to act on your behalf in accordance with the CCPA.
							</li>
						</ul>
						You can opt out from the selling or sharing of your personal information by disabling cookies in
						Cookie Preference Settings and clicking on the Do Not Sell or Share My Personal Information link on
						our homepage.
					</p>
					<p>To exercise these rights, you can contact us by visiting
						https://www.equityworkx.com/privacypolicy#deleteYourAccount, by email at info@equityworkx.com, or by
						referring to the contact details at the bottom of this document. If you have a complaint about how
						we handle your data, we would like to hear from you.</p>
					<h4>Colorado Residents</h4>
					<p>This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the
						rights listed below. However, these rights are not absolute, and in certain cases, we may decline
						your request as permitted by law.
						<ul>
							<li>Right to be informed whether or not we are processing your personal data</li>
							<li>Right to access your personal data</li>
							<li>Right to correct inaccuracies in your personal data</li>
							<li>Right to request deletion of your personal data</li>
							<li>Right to obtain a copy of the personal data you previously shared with us</li>
							<li>Right to opt out of the processing of your personal data if it is used for targeted
								advertising, the sale of personal data, or profiling in furtherance of decisions that
								produce legal or similarly significant effects ("profiling")
							</li>
						</ul>
						We sell personal data to third parties or process personal data for targeted advertising. You can
						opt out from the selling of your personal data, targeted advertising, or profiling by disabling
						cookies in Cookie Preference Settings. To submit a request to exercise any of the other rights
						described above, please email info@equityworkx.com or visit
						https://www.equityworkx.com/privacypolicy#deleteYourAccount.
					</p>
					<p>If we decline to take action regarding your request and you wish to appeal our decision, please email
						us at info@equityworkx.com. Within forty-five (45) days of receipt of an appeal, we will inform you
						in writing of any action taken or not taken in response to the appeal, including a written
						explanation of the reasons for the decisions.</p>
					<h4>Connecticut Residents</h4>
					<p>This section applies only to Connecticut residents. Under the Connecticut Privacy Act (CTDPA), you
						have the
						rights listed below. However, these rights are not absolute, and in certain cases, we may decline
						your request as permitted by law.
						<ul>
							<li>Right to be informed whether or not we are processing your personal data</li>
							<li>Right to access your personal data</li>
							<li>Right to correct inaccuracies in your personal data</li>
							<li>Right to request deletion of your personal data</li>
							<li>Right to obtain a copy of the personal data you previously shared with us</li>
							<li>Right to opt out of the processing of your personal data if it is used for targeted
								advertising, the sale of personal data, or profiling in furtherance of decisions that
								produce legal or similarly significant effects ("profiling")
							</li>
						</ul>
						We sell personal data to third parties or process personal data for targeted advertising. You can
						opt out from the selling of your personal data, targeted advertising, or profiling by disabling
						cookies in Cookie Preference Settings. To submit a request to exercise any of the other rights
						described above, please email info@equityworkx.com or visit
						https://www.equityworkx.com/privacypolicy#deleteYourAccount.
					</p>
					<p>If we decline to take action regarding your request and you wish to appeal our decision, please email
						us at info@equityworkx.com. Within sixty (60) days of receipt of an appeal, we will inform you
						in writing of any action taken or not taken in response to the appeal, including a written
						explanation of the reasons for the decisions.</p>
					<h4>Utah Residents</h4>
					<p>This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you
						have the rights listed below. However, these rights are not absolute, and in certain cases, we may
						decline
						your request as permitted by law.
						<ul>
							<li>Right to be informed whether or not we are processing your personal data</li>
							<li>Right to access your personal data</li>
							<li>Right to request deletion of your personal data</li>
							<li>Right to obtain a copy of the personal data you previously shared with us</li>
							<li>Right to opt out of the processing of your personal data if it is used for targeted
								advertising, the sale of personal data
							</li>
						</ul>
						We sell personal data to third parties or process personal data for targeted advertising. You can
						opt out from the selling of your personal data, targeted advertising, or profiling by disabling
						cookies in Cookie Preference Settings. To submit a request to exercise any of the other rights
						described above, please email info@equityworkx.com or visit
						https://www.equityworkx.com/privacypolicy#deleteYourAccount.
					</p>
					<h4>Virginia Residents</h4>
					<p>nUnder the Virginia Consumer Data Protection Act (VCDPA):</p>
					<p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual
						or household context. It does not include a natural person acting in a commercial or employment
						context.</p>
					<p>"Personal data" means any information that is linked or reasonably linkable to an identified or
						identifiable natural person. "Personal data" does not include de-identified data or publicly
						available information.</p>
					<p>"Sale of personal data" means the exchange of personal data for monetary consideration.</p>
					<p>If this definition of "consumer" applies to you, we must adhere to certain rights and obligations
						regarding your personal data.</p>
					<p className="underline">Your rights with respect to your personal data</p>
					<ul>
						<li>Right to be informed whether or not we are processing your personal data</li>
						<li>Right to access your personal data</li>
						<li>Right to correct inaccuracies in your personal data</li>
						<li>Right to request deletion of your personal data</li>
						<li>Right to obtain a copy of the personal data you previously shared with us</li>
						<li>Right to opt out of the processing of your personal data if it is used for targeted advertising,
							the sale of personal data, or profiling in furtherance of decisions that produce legal or
							similarly significant effects ("profiling")
						</li>
						<li>We sell personal data to third parties or process personal data for targeted advertising. Please
							see the following section to find out how you can opt out from further selling or sharing of
							your personal data for targeted advertising or profiling purposes.
						</li>
					</ul>
					<p className="underline">Exercise your rights provided under the Virginia VCDPA</p>
					<p>You can opt out from the selling of your personal data, targeted advertising, or profiling by
						disabling cookies in Cookie Preference Settings. You may contact us by email at info@equityworkx.com
						or visit https://www.equityworkx.com/privacypolicy#deleteYourAccount.</p>
					<p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized
						agent does not submit proof that they have been validly authorized to act on your behalf.</p>
					<p className="underline">Verification process</p>
					<p>We may request that you provide additional information reasonably necessary to verify you and your
						consumer's request. If you submit the request through an authorized agent, we may need to collect
						additional information to verify your identity before processing your request.</p>
					<p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five
						(45) days of receipt. The response period may be extended once by forty-five (45) additional days
						when reasonably necessary. We will inform you of any such extension within the initial 45-day
						response period, together with the reason for the extension.</p>
					<p className="underline">Right to appeal</p>
					<p>If we decline to take action regarding your request, we will inform you of our decision and reasoning
						behind it. If you wish to appeal our decision, please email us at info@equityworkx.com. Within sixty
						(60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in
						response to the appeal, including a written explanation of the reasons for the decisions. If your
						appeal is denied, you may contact the Attorney General to submit a complaint.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="updatesToThisNotice">
					<h4>DO WE MAKE UPDATES TO THIS NOTICE?</h4>
					<p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
					<p>We may update this privacy notice from time to time. The updated version will be indicated by an
						updated "Revised" date and the updated version will be effective as soon as it is accessible. If we
						make material changes to this privacy notice, we may notify you either by prominently posting a
						notice of such changes or by directly sending you a notification. We encourage you to review this
						privacy notice frequently to be informed of how we are protecting your information.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="contact">
					<h4>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
					<p>If you have questions or comments about this notice, you may email us at info@equityworkx.com</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
				<section className="section" id="personalDataUpdates">
					<h4>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>
					<p>Based on the applicable laws of your country, you may have the right to request access to the
						personal information we collect from you, change that information, or delete it. To request to
						review, update, or delete your personal information, please visit:
						https://www.equityworkx.com/privacypolicy#deleteYourAccount.</p>
					<p className="centerLine"><small><a href="#toc">table of contents</a></small></p>
				</section>
			</div>
			<Footer />
		</>
	)
}