import React from "react";
import logo from "../../assets/equityworkx_logo.png";
import "../../css/App.css";

export function Footer() {
	return (
		<footer className="App-footer">
			<a href="/" className="App-link">
				<img src={logo} className="footer-logo" alt="logo"/>
			</a>
			<a className="App-link" href="/privacy">Privacy Policy</a>
		</footer>
	);
}