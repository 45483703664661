import React from 'react';
import { Footer } from "../components/footer/Footer";
import logo from '../assets/equityworkx_logo.png';
import '../css/App.css';

function App() {
	return (
        <div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo"/>
			</header>
			<Footer />
		</div>);
}

export default App;
