import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'primereact/resources/themes/arya-orange/theme.css';
import { PrimeReactProvider } from "primereact/api";
import App from './screens/App';
import { PrivacyPolicy } from './screens/PrivacyPolicy';
import reportWebVitals from './components/reportWebVitals';

const router = createBrowserRouter( [
    { path: '/', element: <App />},
    { path: '/privacy', element: <PrivacyPolicy /> }
] );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
        <RouterProvider router={router} />
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
